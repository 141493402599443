import { gql } from "urql";

// Get AiPersonality by id query

export const aiPersonalityQuery = gql`
    query AiPersonalityQuery($aiPersonalityId:Int!) {
        aiPersonality(aiPersonalityId:$aiPersonalityId) {
            id
            publicId
            name
            externalName
            temperature
            systemPrompt
            includeRetrival
            searchPrompt
            tags
            languages
            model
            useCache
            showInChat
            reasoningEffort
            tuneThreads {
                edges {
                    node {
                        id
                        positiveReference
                        negativeReference
                        llmJudge
                        tuneThreadItems {
                            edges {
                                node {
                                    id
                                    role
                                    content
                                    toolCallId
                                    toolName
                                    toolArguments
                                    context
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/ai-personality-query";

// Add TuneThread mutation

export const addMutation = gql`
    mutation TuneThreadAddMutation($data:[TuneThreadCreateInput!]!) {
        tuneThreadCreate(data:$data) {
            id
            aiPersonality {
                id
            }
            positiveReference
            negativeReference
            llmJudge
            tuneThreadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolCallId
                        toolName
                        toolArguments
                        context
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/tune-thread-add-mutation";

// Edit TuneThread mutation

export const editMutation = gql`
    mutation TuneThreadEditMutation($data:TuneThreadUpdateInput!) {
        tuneThreadUpdate(data:$data) {
            id
            aiPersonality {
                id
            }
            positiveReference
            negativeReference
            llmJudge
        }
    }
`;

export type * from "./__generated__/tune-thread-edit-mutation";

// Delete TuneThread mutation

export const deleteMutation = gql`
    mutation TuneThreadDeleteMutation($tuneThreadId:Int!) {
        tuneThreadDelete(tuneThreadId:$tuneThreadId) {
            id
            aiPersonality {
                id
            }
            positiveReference
            negativeReference
            llmJudge
            tuneThreadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolCallId
                        toolName
                        toolArguments
                        context
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/tune-thread-delete-mutation";

// Clone TuneThread mutation

export const cloneMutation = gql`
    mutation TuneThreadCloneMutation($tuneThreadId: Int!) {
        tuneThreadClone(tuneThreadId: $tuneThreadId) {
            id
            aiPersonality {
                id
            }
            positiveReference
            negativeReference
            llmJudge
            tuneThreadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolCallId
                        toolName
                        toolArguments
                        order
                        context
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/tune-thread-clone-mutation";

// Add TuneThreadItem mutation

export const addItemMutation = gql`
    mutation TuneThreadAddItemMutation($data:TuneThreadAddItemInput!) {
        tuneThreadAddItem(data:$data) {
            id
            aiPersonality {
                id
            }
            positiveReference
            negativeReference
            llmJudge
            tuneThreadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolCallId
                        toolName
                        toolArguments
                        context
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/tune-thread-add-item-mutation";

// Delete TuneThreadItem mutation

export const deleteItemMutation = gql`
    mutation TuneThreadDeleteItemMutation($data:TuneThreadRemoveItemInput!) {
        tuneThreadRemoveItem(data:$data) {
            id
            aiPersonality {
                id
            }
            positiveReference
            negativeReference
            llmJudge
            tuneThreadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolCallId
                        toolName
                        toolArguments
                        context
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/tune-thread-delete-item-mutation";

// Update TuneThreadItem mutation

export const updateItemMutation = gql`
    mutation TuneThreadUpdateItemMutation($data:TuneThreadUpdateItemInput!) {
        tuneThreadUpdateItem(data:$data) {
            id
            role
            content
            toolName
            toolArguments
            context
        }
    }
`;

export type * from "./__generated__/tune-thread-update-item-mutation";

// Reorder TuneThreadItems mutation

export const reorderItemsMutation = gql`
    mutation TuneThreadReorderItemsMutation($data:TuneThreadReorderItemsInput!) {
        tuneThreadReorderItems(data: $data) {
            id
            aiPersonality {
                id
            }
            positiveReference
            negativeReference
            llmJudge
            tuneThreadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolCallId
                        toolName
                        toolArguments
                        context
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/tune-thread-reorder-items-mutation";

// Ask AI mutation

export const askAiMutation = gql`
    mutation AskAiMutation($data:TuneThreadAskAiInput!) {
        tuneThreadAskAi(data:$data) {
            role
            content
            toolCallId
            toolName
            toolArguments
            context
            retrievalContext {
                query
                context
                hydeQuery
                chunks
                avgRelevance
                documentNames
            }
        }
    }
`;

export type * from "./__generated__/ask-ai-mutation";

// LLM Judge mutation

export const askAiJudgeMutation = gql`
    mutation AskAiJudgeMutation($data:TuneThreadAskAiJudgeInput!) {
        tuneThreadAskAiJudge(data:$data) {
            reasoning
            score
        }
    }
`;

export type * from "./__generated__/ask-ai-judge-mutation";
