import { Box, Group, Stack } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import AiPersonalityForm, { type IFormValues } from "../AiPersonalityForm";
import { type AiPersonalityEditMutation, type AiPersonalityEditMutationVariables, editMutation } from "../queries";
import AddThreadButton from "./AddThreadButton";
import ImportButton from "./ImportButton";
import classes from "./Tune.module.css";
import TuneThread from "./TuneThread";
import type { AiPersonalityQuery } from "./queries";
import useTest from "./useTest";

interface IProps {
    aiPersonality: Exclude<AiPersonalityQuery["aiPersonality"], null>;
}

function Tune({ aiPersonality }: IProps): JSX.Element {
    const navigate = useNavigate();
    const [editResult, executeEdit] = useMutation<AiPersonalityEditMutation, AiPersonalityEditMutationVariables>(
        editMutation,
    );
    const [testLoading, handleTest, testState, testScore] = useTest(aiPersonality);
    const [currentFormValues, setCurrentFormValues] = useState<IFormValues>({
        name: aiPersonality.name,
        externalName: aiPersonality.externalName || "",
        systemPrompt: aiPersonality.systemPrompt,
        temperature: aiPersonality.temperature,
        includeRetrival: aiPersonality.includeRetrival,
        searchPrompt: aiPersonality.searchPrompt || "",
        tags: aiPersonality.tags,
        languages: aiPersonality.languages,
        model: aiPersonality.model,
        useCache: aiPersonality.useCache,
        showInChat: false,
        reasoningEffort: aiPersonality.reasoningEffort,
    });

    const handleSave = async (values: IFormValues) => {
        const result = await executeEdit({
            data: {
                id: aiPersonality.id,
                name: values.name,
                externalName: values.externalName || null,
                systemPrompt: values.systemPrompt,
                temperature: values.temperature,
                includeRetrival: values.includeRetrival,
                searchPrompt: values.includeRetrival ? values.searchPrompt : null,
                tags: values.tags,
                languages: values.languages,
                model: values.model,
                useCache: values.useCache,
                showInChat: aiPersonality.showInChat,
                reasoningEffort: aiPersonality.reasoningEffort,
            },
        });
        if (result.error) {
            notifications.show({
                title: "Failed to save AI personality",
                message: result.error.message,
            });
        } else {
            const aiPersonality = result.data!.aiPersonalityEdit;
            notifications.show({
                title: "AI personality saved",
                message: `AI personality ${aiPersonality.name} updated`,
            });
            navigate("..");
        }
    };

    return (
        <Stack py="md">
            <Group>
                <AddThreadButton aiPersonality={aiPersonality} />
                <ImportButton aiPersonality={aiPersonality} />
            </Group>
            <Box className={classes.scrollTainer}>
                <Box className={classes.scrollRow}>
                    <AiPersonalityForm
                        handleSave={handleSave}
                        handleTest={handleTest}
                        onFormChange={setCurrentFormValues}
                        testScore={testScore}
                        initialValues={currentFormValues}
                        fetching={editResult.fetching}
                        testFetching={testLoading}
                        hideShowInChat
                    />
                    {aiPersonality.tuneThreads.edges.map(({ node }) => (
                        <TuneThread
                            tuneThread={node}
                            key={node.id}
                            testState={testState[node.id]}
                            aiFormValues={currentFormValues}
                            handleTest={handleTest}
                        />
                    ))}
                </Box>
            </Box>
        </Stack>
    );
}

export default Tune;
