import { CodeHighlight } from "@mantine/code-highlight";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Box } from "@mantine/core";
import classes from "./ChatMarkdown.module.css";

interface IProps {
    content: string;
}

function ChatMarkdown({ content }: IProps) {
    return (
        <Box className={classes.markdown}>
            <Markdown
                remarkPlugins={[remarkGfm]}
                components={{
                    code(props) {
                        const { children, className, node, ...rest } = props;
                        const match = /language-(\w+)/.exec(className || "");
                        if (match) {
                            return <CodeHighlight code={String(children).replace(/\n$/, "")} language={match[1]} />;
                        }
                        return (
                            <code {...rest} className={className}>
                                {children}
                            </code>
                        );
                    },
                }}
            >
                {content}
            </Markdown>
        </Box>
    );
}

export default ChatMarkdown;
