import { Box, Button, Divider, Group, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconRobotFace } from "@tabler/icons-react";
import ChatMarkdown from "../../ChatMarkdown";
import Loading from "../../Loading";
import type { IFormValues } from "../AiPersonalityForm";
import AddItemButton from "./AddItemButton";
import CloneThreadButton from "./CloneItemButton";
import DeleteThreadButton from "./DeleteThreadButton";
import EditReferenceButton from "./EditReferenceButton";
import Item, { ICON_SIZE } from "./Item";
import itemClasses from "./Item.module.css";
import Judgement from "./Judgement";
import classes from "./TuneThread.module.css";
import type { AiPersonalityQuery } from "./queries";
import type { IThreadTestState } from "./useTest";

interface IProps {
    tuneThread: Exclude<AiPersonalityQuery["aiPersonality"], null>["tuneThreads"]["edges"][0]["node"];
    testState?: IThreadTestState;
    handleTest?: (values: IFormValues, threadId: number) => void;
    aiFormValues?: IFormValues;
}

function TuneThread({ tuneThread, testState, handleTest, aiFormValues }: IProps) {
    const [threadOpened, { toggle: threadToggle }] = useDisclosure(true);

    return (
        <Stack>
            <Divider
                color="gray"
                label={`Thread ${threadOpened ? "(Hide)" : "(Show)"}`}
                onClick={threadToggle}
                className={classes.clickDivider}
            />
            {threadOpened &&
                tuneThread.tuneThreadItems.edges.map(({ node }) => (
                    <Item item={node} key={node.id} tuneThread={tuneThread} />
                ))}
            <Divider color="gray" label="Result" />
            {testState ? (
                <>
                    {testState.status === "LOADING" && <Loading text="AI is thinking hard..." />}
                    {testState.status === "ERROR" && <Box>Error: {testState.error}</Box>}
                    {testState.status === "SUCCESS" && (
                        <>
                            {testState.items.map((item, index) => (
                                <Item key={index} item={{ ...item, id: 0 }} tuneThread={tuneThread} />
                            ))}
                            {testState.judgement && <Judgement judgement={testState.judgement} />}
                        </>
                    )}
                </>
            ) : (
                <Box>Test the personality to see results</Box>
            )}
            {tuneThread.positiveReference && (
                <>
                    <Divider color="gray" label="Positive reference result" />
                    <Box className={itemClasses.chatBox}>
                        <Stack align="center">
                            <IconRobotFace size={ICON_SIZE} />
                        </Stack>
                        <ChatMarkdown content={tuneThread.positiveReference} />
                    </Box>
                </>
            )}
            {tuneThread.negativeReference && (
                <>
                    <Divider color="gray" label="Negative reference result" />
                    <Box className={itemClasses.chatBox}>
                        <Stack align="center">
                            <IconRobotFace size={ICON_SIZE} />
                        </Stack>
                        <ChatMarkdown content={tuneThread.negativeReference} />
                    </Box>
                </>
            )}
            <Divider color="gray" />
            <Group gap="sm">
                <EditReferenceButton tuneThread={tuneThread} />
                <DeleteThreadButton tuneThread={tuneThread} disabled={testState?.status === "LOADING"} />
                <AddItemButton tuneThread={tuneThread} disabled={testState?.status === "LOADING"} />
                <CloneThreadButton tuneThread={tuneThread} />
                {handleTest && aiFormValues && (
                    <Button
                        onClick={() => {
                            handleTest(aiFormValues, tuneThread.id);
                        }}
                        disabled={testState?.status === "LOADING"}
                        type="button"
                    >
                        Test
                    </Button>
                )}
            </Group>
        </Stack>
    );
}

export default TuneThread;
