import { Box, Modal, Title } from "@mantine/core";
import { useDisclosure, useHover } from "@mantine/hooks";
import { IconSettings } from "@tabler/icons-react";

import ActionButton from "../ActionButton";
import AiPersonalityForm, { type IFormValues } from "../aiPersonalities/AiPersonalityForm";
import type { Language, ReasoningEffortType } from "../utils";
import classes from "./Personality.module.css";

interface IProps {
    aiPersonality: {
        name: string;
        externalName: string | null;
        temperature: number | null;
        systemPrompt: string;
        includeRetrival: boolean;
        searchPrompt: string | null;
        tags: string[];
        languages: Language[];
        model: string;
        useCache: boolean;
        reasoningEffort: ReasoningEffortType | null;
    };
    updatePersonality: (values: IFormValues) => void;
}

function Personality({ aiPersonality, updatePersonality }: IProps): JSX.Element {
    const [opened, { open, close }] = useDisclosure(false);
    const { hovered, ref } = useHover();

    const save = (values: IFormValues) => {
        updatePersonality(values);
        close();
    };

    const initialValues = {
        name: aiPersonality.name,
        externalName: aiPersonality.externalName,
        systemPrompt: aiPersonality.systemPrompt,
        temperature: aiPersonality.temperature,
        includeRetrival: aiPersonality.includeRetrival,
        searchPrompt: aiPersonality.searchPrompt,
        tags: aiPersonality.tags,
        languages: aiPersonality.languages,
        model: aiPersonality.model,
        useCache: aiPersonality.useCache,
        showInChat: false,
        reasoningEffort: aiPersonality.reasoningEffort,
    };

    return (
        <Box className={classes.box} ref={ref}>
            <Title order={3} size="md">
                AI personality: {aiPersonality.name}
            </Title>
            {hovered && (
                <ActionButton
                    onClick={open}
                    label="Tweak AI personality"
                    icon={<IconSettings stroke={1.5} />}
                    className={classes.setting}
                />
            )}
            <Modal opened={opened} onClose={close} title="Tweak AI personality" size="xl">
                <AiPersonalityForm
                    handleSave={save}
                    initialValues={initialValues}
                    fetching={false}
                    hideName
                    hideShowInChat
                />
            </Modal>
        </Box>
    );
}

export default Personality;
