import { gql } from "urql";

// List ai personalities query

export const personalitiesQuery = gql`
    query AiPersonalityListQuery {
        aiPersonalityList(inChat:true) {
            id
            name
            externalName
            temperature
            systemPrompt
            includeRetrival
            searchPrompt
            tags
            languages
            model
            useCache
            reasoningEffort
        }
    }
`;

export type * from "./__generated__/ai-personality-list-query";

// Get thread

export const threadQuery = gql`
    query ThreadQuery($threadId:Int!) {
        thread(threadId:$threadId) {
            id
            threadName
            tokens
            aiPersonality {
                name
                externalName
                temperature
                systemPrompt
                includeRetrival
                searchPrompt
                tags
                languages
                model
                useCache
                reasoningEffort
            }
            user {
                id
            }
            threadItems {
                edges {
                    node {
                        id
                        role
                        content
                        context
                        toolName
                        toolArguments
                        threadItemRetrievalContext {
                            id
                            query
                            context
                            hydeQuery
                            avgRelevance
                            documentNames
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/thread-query";

// Thread page query

export const threadPageQuery = gql`
    query ThreadPageQuery($page:Int!,$filter:ThreadPageFilter!) {
        threadPage(page:$page,filter:$filter) {
            items {
                id
                threadName
                created
                tokens
                user {
                    id
                    firstName
                    lastName
                }
                aiPersonality {
                    id
                    name
                }
            }
            maxPage
        }
    }
`;

export type * from "./__generated__/thread-page-query";

// Create thread mutation

export const createMutation = gql`
    mutation ThreadCreateMutation($data:ThreadCreateInput!) {
        threadCreate(data:$data) {
            id
            threadName
        }
    }
`;

export type * from "./__generated__/thread-create-mutation";

// Ask AI mutation

export const askAiMutation = gql`
    mutation ThreadAskAiMutation($data:ThreadAskAiInput!) {
        threadAskAi(data:$data) {
            id
            threadName
            tokens
            aiPersonality {
                name
                externalName
                temperature
                systemPrompt
                includeRetrival
                searchPrompt
                tags
                languages
                model
                useCache
                reasoningEffort
            }
            threadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolName
                        toolArguments
                        context
                        threadItemRetrievalContext {
                            id
                            query
                            context
                            hydeQuery
                            avgRelevance
                            documentNames
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/thread-ask-ai-mutation";

// Re-ask AI mutation

export const reAskAiMutation = gql`
    mutation ThreadReAskAiMutation($data:ThreadReAskAiInput!) {
        threadReAskAi(data:$data) {
            id
            threadName
            tokens
            aiPersonality {
                name
                externalName
                temperature
                systemPrompt
                includeRetrival
                searchPrompt
                tags
                languages
                model
                useCache
                reasoningEffort
            }
            threadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolName
                        toolArguments
                        context
                        threadItemRetrievalContext {
                            id
                            query
                            context
                            hydeQuery
                            avgRelevance
                            documentNames
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/thread-re-ask-ai-mutation";

// Delete thread mutation

export const deleteMutation = gql`
    mutation ThreadDeleteMutation($threadId:Int!) {
        threadDelete(threadId:$threadId) {
            id
            threadName
        }
    }
`;

export type * from "./__generated__/thread-delete-mutation";

// Attach file mutation

export const attachFileMutation = gql`
    mutation ThreadAttachFileMutation($data:ThreadAttachFileInput!) {
        threadAttachFile(data:$data) {
            id
            threadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolName
                        toolArguments
                        context
                        threadItemRetrievalContext {
                            id
                            query
                            context
                            hydeQuery
                            avgRelevance
                            documentNames
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/thread-attach-file-mutation";

// Attach webpage mutation

export const attachWebpageMutation = gql`
    mutation ThreadAttachWebpageMutation($data:ThreadAttachWebpageInput!) {
        threadAttachWebpage(data:$data) {
            id
            threadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolName
                        toolArguments
                        context
                        threadItemRetrievalContext {
                            id
                            query
                            context
                            hydeQuery
                            avgRelevance
                            documentNames
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/thread-attach-webpage-mutation";

// Document page query

export const documentPageQuery = gql`
    query DocumentPageQuery($page:Int!) {
        documentPage(page:$page) {
            items {
                id
                name
                created
                tokens
                documentType
                processingStatus
                language
                tags
            }
            maxPage
        }
    }
`;

export type * from "./__generated__/document-page-query";

// Attach knowledge mutation

export const attachKnowledgeMutation = gql`
    mutation ThreadAttachKnowledgeMutation($threadId:Int!,$documentId:Int!) {
        threadAttachKnowledge(threadId:$threadId,documentId:$documentId) {
            id
            threadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolName
                        toolArguments
                        context
                        threadItemRetrievalContext {
                            id
                            query
                            context
                            hydeQuery
                            avgRelevance
                            documentNames
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/thread-attach-knowledge-mutation";

// Fork thread mutation

export const forkMutation = gql`
    mutation ThreadForkMutation($threadId:Int!) {
        threadClone(threadId:$threadId) {
            id
            threadName
        }
    }
`;

export type * from "./__generated__/thread-fork-mutation";

// Transcription page query

export const transcriptionPageQuery = gql`
    query TranscriptionPageQuery($page:Int!) {
        transcriptionPage(page:$page) {
            items {
                id
                created
                processingStatus
                language
                name
            }
            maxPage
        }
    }
`;

export type * from "./__generated__/transcription-page-query";

// Attach transcription mutation

export const attachTranscriptionMutation = gql`
    mutation ThreadAttachTranscriptionMutation($threadId:Int!,$transcriptionId:Int!) {
        threadAttachTranscription(threadId:$threadId,transcriptionId:$transcriptionId) {
            id
            threadItems {
                edges {
                    node {
                        id
                        role
                        content
                        toolName
                        toolArguments
                        context
                        threadItemRetrievalContext {
                            id
                            query
                            context
                            hydeQuery
                            avgRelevance
                            documentNames
                        }
                    }
                }
            }
        }
    }
`;

export type * from "./__generated__/thread-attach-transcription-mutation";
