import { gql } from "urql";

// Get AiPersonality by id query

export const aiPersonalityQuery = gql`
    query AiPersonalityQuery($aiPersonalityId:Int!) {
        aiPersonality(aiPersonalityId:$aiPersonalityId) {
            id
            name
            externalName
            temperature
            systemPrompt
            includeRetrival
            searchPrompt
            tags
            languages
            model
            useCache
            showInChat
            reasoningEffort
        }
    }
`;

export type * from "./__generated__/ai-personality-query";

// Get AiPersonality by name query

export interface IGetNameArgs {
    name: string;
}

export const aiPersonalityNameQuery = gql`
    query AiPersonalityByNameQuery($name:String!) {
        aiPersonalityByName(name:$name) {
            id
            name
            externalName
            temperature
            systemPrompt
            includeRetrival
            searchPrompt
            tags
            languages
            model
            useCache
            showInChat
            reasoningEffort
        }
    }
`;

export type * from "./__generated__/ai-personality-by-name-query";

// List ai personalities query

export const listQuery = gql`
    query AiPersonalityListQuery {
        aiPersonalityList(inChat:false) {
            id
            name
            externalName
            created
            model
        }
    }
`;

export type * from "./__generated__/ai-personality-list-query";

// Add AiPersonality mutation

export const addMutation = gql`
    mutation AiPersonalityAddMutation($data:AiPersonalityCreateInput!) {
        aiPersonalityCreate(data:$data) {
            id
            name
            externalName
            temperature
            systemPrompt
            includeRetrival
            searchPrompt
            tags
            languages
            model
            useCache
            showInChat
            reasoningEffort
        }
    }
`;

export type * from "./__generated__/ai-personality-add-mutation";

// Delete AiPersonality mutation

export const deleteMutation = gql`
    mutation AiPersonalityDeleteMutation($aiPersonalityId:Int!) {
        aiPersonalityDelete(aiPersonalityId:$aiPersonalityId) {
            id
            name
        }
    }
`;

export type * from "./__generated__/ai-personality-delete-mutation";

// Edit AiPersonality mutation

export const editMutation = gql`
    mutation AiPersonalityEditMutation($data:AiPersonalityEditInput!) {
        aiPersonalityEdit(data:$data) {
            id
            name
            externalName
            temperature
            systemPrompt
            includeRetrival
            searchPrompt
            tags
            languages
            model
            useCache
            showInChat
            reasoningEffort
        }
    }
`;

export type * from "./__generated__/ai-personality-edit-mutation";
