import {
    Box,
    Button,
    Checkbox,
    Fieldset,
    Group,
    LoadingOverlay,
    MultiSelect,
    Select,
    Slider,
    Stack,
    Text,
    TextInput,
    Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback, useEffect, useState } from "react";
import Hider from "../Hider";
import { useSiteConfig } from "../SiteConfig";
import TagsInput from "../TagsInput";
import {
    LANGUAGES,
    type Language,
    REASONING_EFFORTS,
    type ReasoningEffortType,
    languageLabel,
    reasoningEffortLabel,
} from "../utils";

export interface IFormValues {
    name: string;
    externalName: string | null;
    temperature: number | null;
    systemPrompt: string;
    includeRetrival: boolean;
    searchPrompt: string | null;
    tags: string[];
    languages: Language[];
    model: string;
    useCache: boolean;
    showInChat: boolean;
    reasoningEffort: ReasoningEffortType | null;
}

interface IInnerFormValues {
    name: string;
    externalName: string;
    temperature: number;
    systemPrompt: string;
    includeRetrival: boolean;
    searchPrompt: string;
    tags: string[];
    languages: Language[];
    model: string;
    useCache: boolean;
    showInChat: boolean;
    reasoningEffort: ReasoningEffortType;
}

interface IProps {
    handleSave: (values: IFormValues) => void;
    handleTest?: (values: IFormValues) => void;
    testScore?: number | null;
    initialValues?: IFormValues;
    fetching: boolean;
    hideName?: boolean;
    testFetching?: boolean;
    hideShowInChat?: boolean;
    onFormChange?: (values: IFormValues) => void;
}

const EFFORT_OPTIONS = REASONING_EFFORTS.map((eff) => ({
    label: reasoningEffortLabel(eff),
    value: eff,
}));

function AiPersonalityForm({
    handleSave,
    initialValues,
    fetching,
    hideName,
    handleTest,
    testScore,
    testFetching,
    hideShowInChat,
    onFormChange,
}: IProps): JSX.Element {
    const [submitAction, setSubmitAction] = useState<"save" | "test">("save");
    const config = useSiteConfig();

    const tranformValues = useCallback(
        (values: IInnerFormValues): IFormValues => {
            const modelConfig = config.chatModels.find((model) => model.name === values.model);
            if (!modelConfig) {
                throw new Error(`Model ${values.model} not found`);
            }
            return {
                ...values,
                externalName: values.externalName || null,
                searchPrompt: values.searchPrompt || null,
                temperature: modelConfig.temperature ? values.temperature : null,
                reasoningEffort: modelConfig.reasoningEffort ? values.reasoningEffort || null : null,
            };
        },
        [config.chatModels],
    );

    const handleSubmit = (values: IInnerFormValues) => {
        const newValues = tranformValues(values);
        if (submitAction === "test" && handleTest) {
            return handleTest(newValues);
        }
        if (submitAction === "save") {
            return handleSave(newValues);
        }
    };

    const form = useForm<IInnerFormValues>({
        initialValues: {
            name: initialValues?.name ?? "",
            externalName: initialValues?.externalName ?? "",
            temperature: initialValues?.temperature ?? 0.5,
            systemPrompt: initialValues?.systemPrompt ?? "",
            includeRetrival: initialValues?.includeRetrival ?? false,
            searchPrompt: initialValues?.searchPrompt ?? "",
            tags: initialValues?.tags ?? [],
            languages: initialValues?.languages ?? [],
            model: initialValues?.model ?? "",
            useCache: initialValues?.useCache ?? false,
            showInChat: initialValues?.showInChat ?? true,
            reasoningEffort: initialValues?.reasoningEffort ?? "MEDIUM",
        },
        validate: {
            name: (value) => (value ? null : "Required"),
            systemPrompt: (value) => (value ? null : "Required"),
            searchPrompt: (value, values) => (values.includeRetrival && !value ? "Required" : null),
        },
    });

    useEffect(() => {
        if (onFormChange) {
            onFormChange(tranformValues(form.values));
        }
    }, [form.values, onFormChange, tranformValues]);

    const showTemperature = !!config.chatModels.find((model) => model.name === form.values.model)?.temperature;
    const showResoningEffort = !!config.chatModels.find((model) => model.name === form.values.model)?.reasoningEffort;

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack maw={800} mx="auto">
                <LoadingOverlay visible={fetching} />
                <Hider show={!hideName}>
                    <TextInput withAsterisk label="Name" {...form.getInputProps("name")} />
                </Hider>
                <Hider show={!hideName}>
                    <TextInput label="External name" {...form.getInputProps("externalName")} />
                </Hider>
                <Hider show={!hideShowInChat}>
                    <Checkbox label="Show in chat" {...form.getInputProps("showInChat", { type: "checkbox" })} />
                </Hider>
                <Select
                    label="AI model"
                    placeholder="Select model for AI personality"
                    data={config.chatModels.map((model) => model.name)}
                    {...form.getInputProps("model")}
                />
                <Textarea
                    withAsterisk
                    autosize
                    minRows={3}
                    label="Personality prompt"
                    {...form.getInputProps("systemPrompt")}
                />
                {showTemperature && (
                    <Text size="sm" component="label">
                        Randomnes
                        <Slider
                            min={0.0}
                            max={1.0}
                            step={0.01}
                            value={form.getInputProps("temperature").value}
                            onChange={form.getInputProps("temperature").onChange}
                        />
                    </Text>
                )}
                {showResoningEffort && (
                    <Select
                        label="Reasoning effort"
                        placeholder="How much effort should the model spend on reasoning"
                        data={EFFORT_OPTIONS}
                        {...form.getInputProps("reasoningEffort")}
                    />
                )}
                <Checkbox
                    label="Include knowledge from database"
                    {...form.getInputProps("includeRetrival", { type: "checkbox" })}
                />
                <Hider show={form.values.includeRetrival}>
                    <Fieldset legend="Configure Knowledge Database search">
                        <Text size="sm" mb="md">
                            This provides the AI with a tool called documentSearch that it can use to search the
                            Knowledge Database. It is recommended to mention this tool in and what it is useful for in
                            the personality prompt.
                        </Text>
                        <Textarea autosize label="Search prompt" minRows={3} {...form.getInputProps("searchPrompt")} />
                        <TagsInput form={form} />
                        <MultiSelect
                            label="Languages"
                            data={LANGUAGES.map((lang) => ({ value: lang, label: languageLabel(lang) }))}
                            clearable
                            {...form.getInputProps("languages")}
                        />
                    </Fieldset>
                </Hider>
                <Checkbox label="Cache responses" {...form.getInputProps("useCache", { type: "checkbox" })} />
                <Group justify="flex-end" mt="md">
                    {!!handleTest && (
                        <>
                            {testScore !== undefined && testScore !== null && <Box>Avg score: {testScore}</Box>}
                            <Button type="submit" disabled={testFetching} onClick={() => setSubmitAction("test")}>
                                Test
                            </Button>
                        </>
                    )}
                    <Button type="submit" disabled={fetching} onClick={() => setSubmitAction("save")}>
                        Save
                    </Button>
                </Group>
            </Stack>
        </form>
    );
}

export default AiPersonalityForm;
