import { Box } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { useMutation } from "urql";
import ImportInputForm, { type IParsedInput } from "./ImportInputForm";
import ImportSelectForm, { type IFormValues } from "./ImportSelectForm";
import { type TuneThreadAddMutation, type TuneThreadAddMutationVariables, addMutation } from "./queries";

interface IProps {
    aiPersonality: {
        id: number;
        publicId: string;
        name: string;
    };
    close: VoidFunction;
}

type IItem = TuneThreadAddMutationVariables["data"][0]["items"][0];

function ImportModal({ aiPersonality, close }: IProps) {
    const [_, executeAdd] = useMutation<TuneThreadAddMutation, TuneThreadAddMutationVariables>(addMutation);
    const [parsedData, setParsedData] = useState<IParsedInput | null>(null);

    const add = async (values: IFormValues) => {
        const result = await executeAdd({
            data: values.threads.map((thread) => {
                const refItem = thread.items.find((item) => item.rating != null);
                const referenceContent = refItem?.content ?? null;
                const positiveReference = refItem?.rating === "up" ? referenceContent : null;
                const negativeReference = refItem?.rating === "down" ? referenceContent : null;
                const items = thread.items
                    .filter((item) => item.rating == null)
                    .map((item) => ({
                        role: item.role === "user" ? "USER" : ("ASSISTANT" as IItem["role"]),
                        content: item.content,
                        toolName: item.toolName,
                        toolArguments: item.toolArguments,
                        context: false,
                    }));
                return {
                    aiPersonalityId: aiPersonality.id,
                    positiveReference,
                    negativeReference,
                    items,
                    llmJudge: null,
                };
            }),
        });
        if (result.error) {
            notifications.show({
                title: "Failed to import threads",
                message: result.error.message,
            });
        } else {
            notifications.show({
                title: "Thread addeds",
                message: `Thread addeds to ${aiPersonality.name} for tuning`,
            });
            close();
        }
    };

    return (
        <Box>
            {parsedData === null ? (
                <ImportInputForm handleNext={setParsedData} />
            ) : (
                <ImportSelectForm aiPersonality={aiPersonality} handleSubmit={add} parsedData={parsedData} />
            )}
        </Box>
    );
}

export default ImportModal;
